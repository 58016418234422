<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :span="8">
            <a-form-item label="租户简称" prop="tenant">
              <a-select
                showSearch
                allowClear
                placeholder="请选择客户名称"
                ref="tenant_name"
                v-model="queryParam.tenant"
                @select="handleTenantChange"
                style="width: 100%"
                :filter-option="filterOption"
              >
                <a-select-option v-for="op in tenantOps" :key="op.id + '-' + op.name" :value="op.id">{{
                  op.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="状态">
              <a-select v-model="queryParam['status']" showSearch
                        allowClear style="width: 100%">
                <a-select-option v-for="o in billStatus" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleAudit('basic_audit')">审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleAudit('undo_audit')">反审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleBillApply">开票申请</a-button>
      <a-button type="primary" @click="handleExport">导出</a-button>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }">
      <div slot="feeItem" slot-scope="text, record">
        <div v-for="item in record.rent_fees" :key="item.id">
          <a-popover trigger="click" v-if="item.deny_reason">
            <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
              {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
            </a-tag>
            <template slot="content">
              <div v-for="(reason, index) in item.deny_reason" :key="index">
                <div>驳回人:<a style="margin-right:10px;">{{ reason.deny_person_name }}</a>驳回原因:<a style="margin-right:10px;">{{ reason.deny_reason }}</a>驳回时间:<a>{{ reason.deny_date }}</a></div>
              </div>
            </template>
          </a-popover>
          <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;" v-else>
            {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
          </a-tag>
          {{ item.fee_name ? item.fee_name:'其他' }}:{{ item.charge }}
        </div>
      </div>
    </s-table>

    <a-modal
      :visible="applyFormVisible" :confirmLoading="confirmLoading"
      title="开票申请" :width="980"
      @cancel="applyFormVisible = false"
      @ok="handleApplyFormSubmit"
    >
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-row :gutter="24">
          <a-divider orientation="left">订单信息</a-divider>
          <a-col :span="8">
            <a-form-model-item label="委托单位">
              <a-input v-model="bizAuditForm.customer" :disabled="true" />
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item label="所属部门">
              <a-input v-model="bizAuditForm.department" :disabled="true" />
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item label="录入人">
              <a-input v-model="bizAuditForm.creator" :disabled="true" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="申请时间">
              <a-input v-model="bizAuditForm.applyTime" :disabled="true" />
            </a-form-model-item>
          </a-col>
          <a-divider orientation="left">费用信息
            <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount }}</a-tag>
          </a-divider>
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
            style="padding-left:10px;padding-bottom:10px;"
          >
            全选
          </a-checkbox>
          <a-col :span="24">
            <a-checkbox-group v-model="checkedList" @change="onChange" style="width:100%;">
              <a-row v-for="item in bizAuditForm.fees" :key="item.id">
                <a-alert
                  :message="`业务编号:${item.orderId}`"
                  type="info"
                  show-icon
                  style="margin-bottom:10px;"
                />
                <a-col
                  :span="8"
                  v-for="fee in item.feeList"
                  :key="fee.id"
                  style="padding-left:15px;padding-bottom:10px;"
                >
                  <a-checkbox :value="fee.id"> {{ fee.feeName }}:{{ fee.money }} </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="申请备注">
              <a-input v-model="form.apply_remark"></a-input>
            </a-form-model-item>
          </a-col>
          <a-divider orientation="left">开票信息</a-divider>
          <a-col :span="8">
            <a-form-model-item label="委托单位/客户名称" prop="customer_name">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo2"
                :filter-option="false"
                :not-found-content="fetching2 ? undefined : null"
                @search="getDepartmentCustomer2"
                @change="changeCustomerInfo2"
              >
                <template v-if="fetching2" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerInfoOps2" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="纳税人识别号" prop="tax_no">
              <a-input v-model="form.tax_no" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="地址">
              <a-input v-model="form.address" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="电话">
              <a-input v-model="form.mobile" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="开户银行">
              <a-input v-model="form.bank" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="银行卡号">
              <a-input v-model="form.bank_card_no" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="开票备注" prop="remark">
              <a-input v-model="form.remark"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="税率" prop="tax_rate">
              <a-select v-model="form.tax_rate">
                <a-select-option :value="item.value" v-for="item in taxRateOps" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <edit-table
              :columns="invoiceSubjectColumns"
              :sourceData="invoiceSubjectData"
              :scroll="true"
              :operate="{ add: true, del: true, alldel: false }"
              @getTableDate="getInvoiceSubjectTableDate"></edit-table>
          </a-col>
          <a-col :span="24">
            <span style="font-weight:700;color:red">含税总额：{{ this.totalInvoiceAmount }}</span>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable, EditTable } from '@/components'
import { downloadExcel, getCustomer, getCustomerInfo } from '@/api/common'
import { getMmsFinancePage, applyMmsFinance, exportMmsFinance, mmsFinanceFunc, getMmsRegisterTenantOptions } from '@/api/mms'
import moment from 'moment'
import { getchargeTax, listSecondInvoiceSubject } from '@/api/fms'
import debounce from 'lodash/debounce'
import { filterOption } from '@/utils/util'
export default {
  components: {
    STable, EditTable
  },
  data() {
    this.lastFetchId2 = 0;
    this.getDepartmentCustomer2 = debounce(this.getDepartmentCustomer2, 800);
    return {
      totalAmount: 0,
      checkedList: [],
      indeterminate: false,
      checkAll: true,
      taxRateOps: [],
      queryParam: {},
      tenantOps: [],
      selectedRowKeys: [],
      selectedRows: [],
      billStatus: {
        0: { value: 0, name: '已支付' },
        1: { value: 1, name: '开票中' },
        2: { value: 2, name: '已开票' },
        3: { value: 3, name: '已核销' }
      },
      columns: [
        {
          title: '业务编号',
          dataIndex: 'serial_num'
        },
        {
          title: '客户名称',
          dataIndex: 'short_code'
        },
        {
          title: '租赁地址',
          dataIndex: 'address'
        },
        {
          title: '租赁费用',
          dataIndex: 'rent_fees',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '应收合计',
          customRender: (text, record) => {
            let fees = 0
            record.rent_fees.forEach(v => {
              fees += v.charge
            })
            return fees
          }
        },
        {
          title: '应收备注',
          dataIndex: 'charge_remark'
        }
      ],
      loadData: parameter => {
        return getMmsFinancePage(Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      applyFormVisible: false,
      confirmLoading: false,
      form: {
        apply_remark: '',
        customer_name: '',
        customer_id: null,
        tax_no: '',
        address: '',
        mobile: '',
        bank: '',
        bank_card_no: '',
        remark: '',
        tax_rate: undefined,
        invoice_subject_data: [],
        invoice_remark: ''
      },
      bizAuditForm: {
        audit_record_id: '',
        customer: '',
        department: '总经办',
        creator: '',
        applyTime: moment().format('L'),
        fees: null,
        total: null
      },
      customerInfo2: undefined,
      fetching2: false,
      customerInfoOps2: [],
      rules: {
        customer_name: [
          { required: true, message: '请输入委托单位/客户名称', trigger: 'blur' }
        ],
        tax_no: [
          { required: true, message: '请输入纳税人识别号', trigger: 'blur' }
        ],
        tax_rate: [{ required: true, message: '请选择税率', trigger: 'change' }],
        remark: [{ required: true, message: '请输入开票备注', trigger: 'blur' }]
      },
      totalInvoiceAmount: 0.00,
      taxRateList: [{ value: 0, name: 0 },
        { value: 0.01, name: 0.01 },
        { value: 0.03, name: 0.03 },
        { value: 0.05, name: 0.05 },
        { value: 0.06, name: 0.06 },
        { value: 0.09, name: 0.09 },
        { value: 0.13, name: 0.13 },
        { value: 0.15, name: 0.15 },
        { value: 0.16, name: 0.16 },
        { value: 0.17, name: 0.17 }],
      secondInvoiceSubjectList: [],
      invoiceSubjectData: [],
      invoiceSubjectColumns: [
        {
          title: `货物或应税劳务,服务名称`,
          dataIndex: `goods_name`,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  let subject = null
                  debugger
                  for (const item of this.secondInvoiceSubjectList) {
                    if (item.id === val) {
                      subject = item
                    }
                  }
                  this.invoiceSubjectData[index]['subject_id'] = subject.id
                  this.invoiceSubjectData[index]['goods_name'] = subject.subject_name
                  this.invoiceSubjectData[index]['goods_code'] = subject.subject_code
                  this.invoiceSubjectData[index]['goods_specification'] = subject.subject_spec
                  this.invoiceSubjectData[index]['goods_unit'] = subject.subject_unit
                  this.invoiceSubjectData[index]['goods_tax_rate'] = subject.tax_rate
                }}
              >
                {this.secondInvoiceSubjectList.map(op => {
                  var opName = op.subject_name
                  if (op.subject_spec) {
                    opName = opName + '/' + op.subject_spec
                  }
                  opName = opName + '/' + op.tax_rate
                  return <a-select-option key={op.id}>{opName}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `金额`,
          dataIndex: `goods_total_price`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_price'] = val
                  if (this.invoiceSubjectData[index]['goods_tax_rate']) {
                    var taxRate = this.invoiceSubjectData[index]['goods_tax_rate']
                    this.invoiceSubjectData[index]['goods_total_tax'] = (val / (1 + taxRate) * taxRate).toFixed(2)
                  } else {
                    this.invoiceSubjectData[index]['goods_total_tax'] = 0
                  }
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `税率`,
          dataIndex: `goods_tax_rate`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_tax_rate'] = val
                  if (this.invoiceSubjectData[index]['goods_total_price']) {
                    var totalPrice = this.invoiceSubjectData[index]['goods_total_price']
                    this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice / (1 + val) * val).toFixed(2)
                  }
                  this.calcTotalPrice(false)
                }}
              >
                {this.taxRateList.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `税额`,
          dataIndex: `goods_total_tax`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_tax'] = val
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        }
      ]
    }
  },
  created() {
    getMmsRegisterTenantOptions().then(v => {
      this.tenantOps = v
    });
    getchargeTax().then(v => {
      this.taxRateOps = v
    })
    listSecondInvoiceSubject({ department: 3 }).then(v => {
      this.secondInvoiceSubjectList = v
    })
  },
  methods: {
    filterOption,
    getDepartmentCustomer2(value) {
      this.lastFetchId2 += 1;
      const fetchId2 = this.lastFetchId2;
      this.customerInfoOps2 = [];
      this.fetching2 = true;
      getCustomer({
        department: null,
        customerName: value
      }).then(res => {
        if (fetchId2 !== this.lastFetchId2) {
          return;
        }
        this.customerInfoOps2 = res;
        this.fetching2 = false;
      })
    },
    changeCustomerInfo2(value) {
      this.customerInfo2 = value;
      this.form.customer_name = value !== undefined ? value.label : null;
      this.customerInfoOps2.forEach(item => {
        if (item.value === value.key) {
          this.form.customer_id = value.key
          this.form.tax_no = item.taxNum;
          this.form.address = item.street;
          this.form.mobile = item.phone;
          this.form.bank_card_no = item.account;
          this.form.bank = item.bankName;
        }
      });
      this.customerInfoOps2 = [];
      this.fetching2 = false;
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleAudit(operate) { // 业务审核与反审核
      const data = [];
      this.selectedRows.forEach(v => {
        v.rent_fees.forEach(fee => {
          switch (operate) {
            // 审核
            case 'basic_audit':
              if (fee.fee_status === 1) data.push(v.id);
              break;
            // 反审核
            case 'undo_audit':
              if (fee.fee_status === 2) data.push(v.id);
              break;
          }
        });
      });
      if (data.length > 0) {
        mmsFinanceFunc(operate, {
          ids: data
        }).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          });
          this.selectedRows = [];
          this.selectedRowKeys = [];
          this.$refs.table.refresh(false);
        });
      } else {
        this.$message.warning(operate === 'basic_audit' ? '请选择已对账待审核的数据' : '请选择业务已审核的数据');
      }
    },
    handleBillApply() {
      this.checkedList = []
      let flag = false
      const feeMaps = []
      let total = 0
      let chargeRemark = ''
      this.selectedRows.forEach(row => {
        const orderFee = {
          orderId: row.serial_num,
          feeList: row.rent_fees && row.rent_fees.filter(e => e.fee_status === 2 || e.fee_status === 4).map(e => {
            total += e.charge ? e.charge : 0
            if (e.fee_status === 2 || e.fee_status === 4) {
              flag = true
            }
            this.checkedList.push(e.id)
            return {
              id: e.id,
              feeName: e.fee_name,
              money: e.charge
            }
          })
        }
        chargeRemark += row.charge_remark
        feeMaps.push(orderFee)
      })
      if (flag) {
        this.customerInfo2 = undefined
        this.form.customer_id = null
        this.form.tax_no = null
        this.form.address = null
        this.form.mobile = null
        this.form.bank_card_no = null
        this.form.bank = null
        this.getCustomerOps(this.selectedRows[0].short_code)
        this.bizAuditForm = {
          ...this.bizAuditForm,
          customer: this.selectedRows[0].short_code,
          fees: feeMaps,
          total: total,
          creator: this.selectedRows[0].creator
        }
        this.form.tax_rate = undefined
        this.form.apply_remark = chargeRemark
        this.form.remark = chargeRemark
        this.totalAmount = total
        this.applyFormVisible = true
      } else {
        this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
      }
    },
    getCustomerOps(name) {
      getCustomerInfo({
        department: null,
        customerName: name
      }).then(res => {
        if (res && res.length > 0) {
          const data = res[0]
          this.customerInfo2 = {
            key: typeof data.value !== 'undefined' ? data.value : '',
            label: typeof data.name !== 'undefined' ? data.name : ''
          }
          this.form.customer_id = typeof data.value !== 'undefined' ? data.value : ''
          this.form.customer_name = typeof data.name !== 'undefined' ? data.name : ''
          this.form.tax_no = typeof data.taxNum !== 'undefined' ? data.taxNum : ''
          this.form.address = typeof data.street !== 'undefined' ? data.street : ''
          this.form.mobile = typeof data.phone !== 'undefined' ? data.phone : ''
          if (typeof data.bankAccount !== 'undefined') {
            this.bankAccountOps = data.bankAccount
            this.form.bank_card_no = typeof data.bankAccount[0].account !== 'undefined' ? data.bankAccount[0].account : ''
            this.form.bank = typeof data.bankAccount[0].bank_name !== 'undefined' ? data.bankAccount[0].bank_name : ''
          }
        }
      })
    },
    handleApplyFormSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.form.invoice_subject_data = this.invoiceSubjectData
          this.form.invoice_remark = this.form.remark
          const data = {
            ...this.form,
            id_list: this.checkedList
          }
          applyMmsFinance(data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '申请成功.'
            })
            this.selectedRows = []
            this.selectedRowKeys = []
            this.applyFormVisible = false
            this.invoiceSubjectData = []
            this.form.invoice_subject_data = []
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleExport() {
      if (this.queryParam.status) {
        exportMmsFinance(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = {
              fileName: res
            }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        })
      } else {
        this.$notification['warning']({
          message: '提示',
          description: '请选择需要导出的状态.'
        })
      }
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    onChange(checkedValues) {
      let count = 0
      this.totalAmount = 0
      for (const item of this.bizAuditForm.fees) {
        count += item.feeList.length
        for (let i = 0; i < checkedValues.length; i++) {
          for (let j = 0; j < item.feeList.length; j++) {
            if (checkedValues[i] === item.feeList[j].id) {
              this.totalAmount += item.feeList[j].money
            }
          }
        }
      }
      this.indeterminate = !!this.checkedList.length && this.checkedList.length < count
      this.checkAll = this.checkedList.length === count
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked
      this.indeterminate = false
      this.checkedList = []
      if (e.target.checked) {
        this.bizAuditForm.fees.forEach(item => {
          for (const e of item.feeList) {
            this.totalAmount += e.money
            this.checkedList.push(e.id)
          }
        })
        this.totalAmount = this.bizAuditForm.total
      } else {
        this.totalAmount = 0
      }
    },
    getInvoiceSubjectTableDate(data) {
      this.invoiceSubjectData = data
    },
    calcTotalPrice(againFlag) {
      this.totalInvoiceAmount = 0
      this.invoiceSubjectData.forEach(v => {
        if (againFlag) {
          if (v.goods_quantity && v.goods_price && v.goods_tax_rate) {
            v.goods_total_price = v.goods_quantity * v.goods_price
            v.goods_total_tax = (v.goods_total_price / (1 + v.goods_tax_rate) * v.goods_tax_rate).toFixed(2)
          }
        }
        if (v.goods_total_price) {
          this.totalInvoiceAmount = this.totalInvoiceAmount + v.goods_total_price
        }
      })
    }
  }
}
</script>
